<template>
  <v-dialog max-width="600" v-model="status" scrollable persistent transition="dialog-transition">
    <v-card>
      <v-card-title>
        <span v-if="!isEditing">Create a new location</span>
        <span v-else>Editing Location</span>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal">close</v-icon>
      </v-card-title>
      <v-card-subtitle class="pb-0"  v-if="isEditing">
        For further changes, visit
        <a target="_blank" :href="goToLocationsAdminPanel">locations admin panel</a>.
      </v-card-subtitle>
      <v-card-subtitle class="pb-0"  v-else>
        For further changes after creating, visit
        <a target="_blank" :href="goToLocationsAdminPanel">locations admin panel</a>
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="crudLocationForm" @submit.prevent>
          <v-text-field v-model="newLocation.name" name="name" label="Location name"></v-text-field>
          <v-text-field v-model="newLocation.display_name" name="display_name" label="Display Name"></v-text-field>
          <v-text-field v-model="newLocation.internal_id" name="internalId" label="Internal ID"></v-text-field>
          <v-switch inset v-model="newLocation.is_corporate" label="Corporate Location"></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="isEditing"
          @click="deleteLocation"
          elevation="0"
          color="error"
          text
          class
        >Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="closeModal" color="grey" text class="text-capitalize">Cancel</v-btn>
        <v-btn @click="handleConfirm" elevation="0" color="primary" class="text-capitalize">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable camelcase */
import { LocationsAPI } from '@/clients/locations/locationsapi'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      newLocation: {
        id: null,
        name: '',
        display_name: '',
        internal_id: '',
        is_corporate: false
      }
    }
  },
  props: {
    location: {
      type: Object,
      default: () => { return {} }
    },
    status: {
      default: false
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    async createNewLocation() {
      this.spinner(true)
      let response
      try {
        response = await LocationsAPI.create(this.newLocation)
      } catch (error) {
        this.swal({
          title: 'Error',
          text: error.response.data.msg,
          type: 'error'
        })
        return console.log(error)
      }
      this.spinner(false)
      this.$emit('location-created', response.data.payload)
      this.closeModal()
    },
    handleConfirm() {
      if (this.isEditing) {
        this.updateLocation()
      } else {
        this.createNewLocation()
      }
    },
    async updateLocation() {
      this.spinner(true)
      let response
      try {
        response = await LocationsAPI.updateById(this.location.id, this.newLocation)
      } catch (error) {
        this.swal({
          title: 'Error',
          text: error.response.data.msg,
          type: 'error'
        })
        return console.log(error)
      }
      this.spinner(false)
      this.$emit('location-updated', response.data.payload)
      this.closeModal()
    },
    deleteLocation() {
      this.$emit('location-delete', this.location)
    }
  },
  computed: {
    ...mapGetters({
      vg_locationsApplicationDetails: 'applications/isLocationsEnabled'
    }),
    isEditing() {
      return this.location !== null && Object.keys(this.location).length > 0
    },
    goToLocationsAdminPanel() {
      if (this.isEditing) {
        return `${process.env.VUE_APP_LOCATIONS_APP_URL}/profile/${this.location.id}/store-info`
      }
      return `${process.env.VUE_APP_LOCATIONS_APP_URL}/locations`
    }
  },
  watch: {
    location: {
      immediate: true,
      handler: function (x) {
        console.log('@handler, watcher', x)
        if (this.isEditing) {
          console.log('here')
          Object.keys(this.location).forEach(key => { this.newLocation[key] = this.location[key] })
        }
      }
    }
  }
}
</script>

<style>
</style>
